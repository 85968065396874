import React, { Component, useState, createRef } from "react";

import commonFunction from "../../../lib/utils/common";
import { debounce } from "lodash";
// import { getGuestList } from "../../../../common/lib/guestSuggest";
import {
  getClient,
  addClient,
  updateClient,
} from "../../../../common/lib/clients/clientsAPI";
export default class GuestDetail extends Component {
  constructor(props) {
    super(props);
    console.log("erroermsx", this.props.props.errorMessage);
    console.log("cffdgvdf", this.props);
    this.guestDetailWrapper = createRef();
    this.state = {
      setAddGuest: [],
      setGuest: [],
      guestId: "",
      isAddTab: true,
      openTraveler: false,
      showDefaultText: true,
    };
  }

  handleClickOutside = () => {
    if (
      this.guestDetailWrapper.current &&
      !this.guestDetailWrapper.current.contains(event.target)
    ) {
      this.setState({ openTraveler: false });
    }
  };

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  searchGuest = debounce((event) => {
    let requestedData = {
      active: true,
      search_text: event.target.value,
      client_id: "",
      member_type: "SELF",
    };
    if (event.target.value) {
      getClient(requestedData).then((data) =>
        // console.log("data==>",data)
        this.setState({ setGuest: data?.list, showDefaultText: false })
      );
    } else {
      this.setState({ setGuest: [] });
    }
  }, 200);

  onClickTraveller = () => {
    console.log("123");
    if (!this.state.openTraveler) {
      this.setState({
        openTraveler: true,
        setGuest: [],
      });
    } else {
      this.setState({
        openTraveler: false,
        setGuest: [],
      });
    }
  };

  addGuest = async (first_name, last_name, userid, email, nationality) => {
    // console.log(first_name,last_name,userid,email)
    let isAdd = true;
    await this.setState({
      setAddGuest: [
        {
          f_name: first_name,
          l_name: last_name,
          mail: email,
          userid: userid,
          nationality: nationality,
        },
      ],
    });
    await this.setState({ guestId: userid });
    await this.props.props?.addGuestId(this.state.guestId);
    (await this.props?.props?.clientNationality)
      ? this.props?.props?.clientNationality(nationality, userid, email, isAdd)
      : null;
    await this.setState({ isAddTab: false });
  };

  removeGuest = async (first_name, last_name, userid, email, nationality) => {
    console.log(
      "first_name,last_name,userid,email,nationality",
      first_name,
      last_name,
      userid,
      email,
      nationality
    );
    let isAdd = false;
    this.setState({ setAddGuest: (this.state.setAddGuest = 0) });
    this.setState({ guestId: "" });
    await this.props.props.removeGuestId(this.state.guestId);
    (await this.props?.props?.clientNationality)
      ? this.props?.props?.clientNationality(nationality, userid, email, isAdd)
      : null;
    await this.setState({ isAddTab: true });
  };
  render() {
    console.log("===>", this.state?.setGuest);
    console.log("this.statesvdsdv", this.props);
    return (
      <div
        ref={this.guestDetailWrapper}
        className={this.props?.props?.styles?.collapsible_title_add}
      >
        <div className="flex">
          <div className="fname form-inputs addguestbtn-width" id="htlguestdiv">
            <div className={this.props.props.styles?.addguestbtn}>
              <div className={this.props.props.styles?.trav_flex}>
                {/* <label for="fname" className="lablename-htl">
                  {" "}
                  &nbsp;
                </label> */}
                <span className={this.props.props.styles?.travtext}>
                  <strong> {"  Traveler:"} </strong>
                </span>

                {this.state?.setAddGuest[0]?.f_name && (
                  <p className={this.props.props.styles.travNameCap}>
                    {(this.state?.setAddGuest[0]?.f_name).toUpperCase()}{" "}
                    {this.state?.setAddGuest[0]?.l_name
                      ? (this.state?.setAddGuest[0]?.l_name).toUpperCase()
                      : ""}
                  </p>
                )}
                <div
                  className={this.props.props.styles?.adju_flex}
                  style={{ cursor: "pointer" }}
                  // onClick={() => this.onClickTraveller()}
                >
                  <button
                    type="button"
                    className={this.props.props?.styles?.traveller_button}
                    id=""
                  >
                    <span
                      className={this.props.props.styles.plus_arr}
                      onClick={() => this.onClickTraveller()}
                    >
                      {this.state.openTraveler ? (
                        <img src="/theme4/adv_minus.svg" />
                      ) : (
                        <img src="/theme4/adv_plus.svg" />
                      )}
                    </span>
                  </button>
                  <span
                    className={this.props.props.styles.guesttext}
                    onClick={() => this.onClickTraveller()}
                  >
                    {"  Add Client(s)"}{" "}
                  </span>
                </div>
              </div>
              {!commonFunction.isValueEmpty(this.props.props.isError) &&
              this.props.props.isError === true ? (
                <div
                  style={{
                    fontSize: "11px",
                    color: "#e8664e",
                    marginTop: "3px",
                  }}
                >
                  <span>
                    {!commonFunction.isValueEmpty(this.props.props.errorMessage)
                      ? this.props.props.errorMessage
                      : ""}
                  </span>
                </div>
              ) : null}
              <div id="guest_count" className="guest_counts" value=""></div>
              {this.state?.openTraveler && (
                <div
                  id="myGuest"
                  className={this.props.props.styles.dropdown_Guest_container}
                >
                  <div className={this.props.props.styles.inputsearch}>
                    <input
                      type="text"
                      id="myInputNation"
                      // onKeyUp="searchFuest(event)"
                      onChange={(event) => this.searchGuest(event)}
                      placeholder="Search Client"
                    />
                  </div>

                  <div
                    id="guestlist"
                    className={this.props.props.styles.gest_container}
                  >
                    {this.state?.setAddGuest?.length ? (
                      <div className={this.props.props.styles.travel_gest}>
                        <p>Remove Client</p>
                        <div className={this.props.props.styles.travlr_scroll}>
                          {this.state?.setAddGuest?.map((item) => (
                            <div
                              className={this.props.props.styles.travlr_wrap}
                              onClick={() => {
                                this.removeGuest(
                                  item.f_name,
                                  item.l_name,
                                  item.userid,
                                  item.mail,
                                  item.nationality
                                );
                              }}
                            >
                              <div
                                className={this.props.props.styles.travlr_name}
                              >
                                <span>{item.mail}</span>
                                <br />
                                <span
                                  className={
                                    this.props.props.styles.client_name
                                  }
                                >
                                  {item.f_name + " " + item.l_name}
                                </span>
                              </div>
                              <button
                                className={this.props.props.styles.travlr_btn}
                                onClick={() => {
                                  this.removeGuest(
                                    item.f_name,
                                    item.l_name,
                                    item.userid,
                                    item.mail,
                                    item.nationality
                                  );
                                }}
                              >
                                -
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : this.state?.setGuest?.length && this.state?.isAddTab ? (
                      <div className={this.props.props.styles.travel_gest}>
                        <p>Add Client</p>
                        <div className={this.props.props.styles.travlr_scroll}>
                          {this.state?.setGuest?.map((item) => (
                            <div
                              className={this.props.props.styles.travlr_wrap}
                              key={item.id}
                              onClick={() => {
                                this.addGuest(
                                  item.first_name,
                                  item.last_name,
                                  item._id,
                                  item.email,
                                  item.nationality
                                );
                              }}
                            >
                              {/* {console.log("item",item)} */}
                              <div
                                className={this.props.props.styles.travlr_name}
                              >
                                <span> {item.email}</span>
                                <br />
                                <span>
                                  Client:{" "}
                                  <span
                                    className={
                                      this.props.props.styles.client_name
                                    }
                                  >
                                    {item.first_name + " " + item.last_name}
                                  </span>
                                </span>
                              </div>
                              <button
                                className={this.props.props.styles.travlr_btn}
                                onClick={() => {
                                  this.addGuest(
                                    item.first_name,
                                    item.last_name,
                                    item._id,
                                    item.email,
                                    item.nationality
                                  );
                                }}
                              >
                                +
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div style={{ paddingTop: 20, textAlign: "center" }}>
                        <h3>
                          {this.state.showDefaultText
                            ? "Search by Name or Email ID"
                            : "No Result Found"}
                        </h3>
                      </div>
                    )}
                  </div>

                  {/* Old Flow ===>  */}
                  {/* <div
                      id="guestlist"
                      className={this.props.props.styles.gest_container}
                    >
                      {this.state?.setAddGuest?.length ? (
                        <div className={this.props.props.styles.travel_gest}>
                          <p>Remove Client</p>
                          <div
                            className={this.props.props.styles.travlr_scroll}
                          >
                            {this.state?.setAddGuest?.map((item) => (
                              
                              <div
                                className={this.props.props.styles.travlr_wrap}
                                onClick={() => {
                                  this.removeGuest(
                                    item.f_name,
                                    item.l_name,
                                    item.userid                                      ,
                                    item.mail,
                                    item.nationality
                                  );
                                }}
                              >
                                <div
                                  className={
                                    this.props.props.styles.travlr_name
                                  }
                                >
                                  <span>{item.mail}</span>
                                  <br />{console.log("item",item)}
                                  <span className={this.props.props.styles.client_name}>{item.f_name + " " + item.l_name}</span>
                                </div>
                                <button
                                  className={this.props.props.styles.travlr_btn}
                                  onClick={() => {
                                    this.removeGuest(
                                      item.f_name,
                                      item.l_name,
                                      item.userid                                      ,
                                      item.mail,
                                      item.nationality
                                    );
                                  }}
                                >
                                  -
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      
                      {this.state?.setGuest?.length && this.state?.isAddTab ? (
                        <div className={this.props.props.styles.travel_gest}>
                          <p>Add Client</p>
                          <div
                            className={this.props.props.styles.travlr_scroll}
                          >
                            {this.state?.setGuest?.map((item) => (
                              <div
                                className={this.props.props.styles.travlr_wrap}
                                key={item.id}
                                onClick={() => {
                                  this.addGuest(
                                    item.first_name,
                                    item.last_name,
                                    item._id,
                                    item.email,
                                    item.nationality
                                  );
              
                                }}
                              >
                               
                                <div
                                  className={
                                    this.props.props.styles.travlr_name
                                  }
                                >
                                  <span> {item.email}</span>
                                  <br />
                                  <span>Client:
                                    {" "}
                                    <span   className={this.props.props.styles.client_name}>{item.first_name + " " + item.last_name}</span>
                                  </span>
                                </div>
                                <button
                                  className={this.props.props.styles.travlr_btn}
                                  onClick={() => {
                                    this.addGuest(
                                      item.first_name,
                                      item.last_name,
                                      item._id,
                                      item.email,
                                      item.nationality
                                    );
                
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
